<template>
    <div style="background-color:#eee !important">
        <weacg-easy-read-time v-if="!isCustom && isTime" :currentYearDiaryCount="currentYearDiaryCount" :currentYearImgCount="currentYearImgCount"
            :currentYearTimeLineCount="currentYearTimeLineCount" :currentYearWishCount="currentYearWishCount"
            :day="days"></weacg-easy-read-time>
        <h2 class="count_title" :style="'padding-top: 60px'">{{ title }}</h2>
        <ul id="countdown">
            <li id="days">
                <div class="number">{{ days }}</div>
                <div class="label">Days</div>
            </li>
            <li id="hours">
                <div class="number">{{ hrs }}</div>
                <div class="label">Hours</div>
            </li>
            <li id="minutes">
                <div class="number">{{ min }}</div>
                <div class="label">Minutes</div>
            </li>
            <li id="seconds">
                <div class="number">{{ sec }}</div>
                <div class="label">Seconds</div>
            </li>
        </ul>

        <!-- <weacg-time-line></weacg-time-line> -->
    </div>
</template>

<script>
export default {
    name: 'weacgCountUp',
    props: {
        title: {
            type: String,
            default: '与你相识的'
        },
        currentYearDiaryCount: {
            type: Number
        },
        currentYearImgCount: {
            type: Number
        },
        currentYearTimeLineCount: {
            type: Number
        },
        currentYearWishCount: {
            type: Number
        },
        isTime: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            targetDate: new Date('2022/05/19 15:07:00'),
            days: null,
            hrs: null,
            min: null,
            sec: null
        }
    },
    computed: {
        isCustom() {
            return this.$store.state.isCustom
        }
    },
    created() { },
    mounted() {
        this.timeToLaunch()
        // Transition the current countdown from 0
        // Begin Countdown
        setTimeout(this.countDownTimer, 1001)
    },
    methods: {
        countDownTimer() {
            // Figure out the time to launch
            this.timeToLaunch()

            //this.$emit('timer')

            // Repeat the check every second
            setTimeout(this.countDownTimer, 1000)
        },

        timeToLaunch() {
            // Get the current date
            var currentDate = new Date()

            // Find the difference between dates
            var diff = (currentDate - this.targetDate) / 1000
            diff = Math.abs(Math.floor(diff))

            // Check number of days until target
            this.days = Math.floor(diff / (24 * 60 * 60))
            this.sec = diff - this.days * 24 * 60 * 60

            // Check number of hours until target
            this.hrs = Math.floor(this.sec / (60 * 60))
            this.sec = this.sec - this.hrs * 60 * 60

            // Check number of minutes until target
            this.min = Math.floor(this.sec / 60)
            this.sec = this.sec - this.min * 60
        },

        $(Nid) {
            var ele = document.getElementById(Nid)
            return ele
        }
    }
}
</script>

<style lang="scss" scoped>
.count_title {
    font-family: 'Indie Flower', cursive !important;
    margin: 0 auto;
    text-align: center;
    padding: 8px 0;
    color: #555;
}
ul#countdown {
    position: relative;
    top: 50%;
    width: 80vw;
    margin: 0 auto;
    padding: 15px 0 20px 0;
    color: #fff;
    border: 1px solid #adafb2;
    border-width: 1px 0;
    overflow: hidden;
    font-family: 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;

    li {
        margin: 0 -3px 0 0;
        padding: 0;
        display: inline-block;
        width: 25%;
        font-size: 72px;
        font-size: 6vw;
        text-align: center;

        .label {
            color: #adafb2;
            font-size: 18px;
            font-size: 1.5vw;
            text-transform: uppercase;
        }
    }
}
</style>
