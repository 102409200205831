import { render, staticRenderFns } from "./animButton.vue?vue&type=template&id=711d043f&scoped=true&"
import script from "./animButton.vue?vue&type=script&lang=js&"
export * from "./animButton.vue?vue&type=script&lang=js&"
import style0 from "./animButton.vue?vue&type=style&index=0&id=711d043f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "711d043f",
  null
  
)

export default component.exports