export default [
    {
        path: '/401',
        name: '401',
        component: () => import('./401')
    },
    {
        path: '/403',
        name: '403',
        component: () => import('./403')
    },
    {
        path: '*',
        name: '404',
        component: () => import('./404')
    }
]
