<template>
    <div class="audio_wrap">
        <h1 class="title">{{ musicData.title }}</h1>
        <aplayer
            theme="pic"
            :list="musicData.data"
            :music="currentMusic"
            :showLrc="true"
        />
        <a
            :href="musicData.link || ''"
            class="musicLink"
            >去网易云收藏聆听 >>>
        </a>
    </div>
</template>

<script>
import Aplayer from 'vue-aplayer'

export default {
    name: 'weacgAudioPlayer',
    components: { Aplayer },
    props: {
        musicData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            currentMusic:
                this.musicData.data && this.musicData.data.length > 0
                    ? this.musicData.data[0]
                    : this.musicData.default
        }
    }
}
</script>

<style lang="less" scoped>
.audio_wrap{
    padding-top: 5vh;
}
.aplayer {
    margin: 0;
}
h1 {
    font-size: 200%;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
}
p.leader {
    margin-top: 20px;
    text-align: center;
    max-width: 90%;
    margin: auto;
    padding-bottom: 45px;
}
.musicLink {
    text-align: center;
    color: #555;
    text-decoration: none;
    margin: 0 auto;
    width: 100%;
    display: block;
    margin-top: 10px;
}
</style>
