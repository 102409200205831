var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weacg_icon",style:({
        padding: _vm.paddingsize + 'px',
        width: _vm.size + 'px',
        height: _vm.size + 'px'
    })},[_c('svg',{staticClass:"svg-icon",style:({
            width: _vm.size - 2 * _vm.paddingsize + 'px',
            height: _vm.size - 2 * _vm.paddingsize + 'px'
        }),attrs:{"fill":_vm.iconColor}},[_c('use',{attrs:{"xlink:href":_vm.iconNameCp}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }