<template>
    <div class="wrapper-no4">
        <div @click="submitMsg" for="send" class="send" :class="{ checked: checked }">
            <div class="rotate">
                <div class="move">
                    <div class="part left"></div>
                    <div class="part right"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'weacgSendButton',
    data() {
        return {
            checked: false,
            isAnim: false
        }
    },
    methods: {
        submitMsg() {
            this.$emit('btn-click')
        }
    },
    watch:{
        isAnim:{
            handler() {
                if(this.isAnim){
                    setTimeout(() => {
                        this.isAnim = false
                        this.checked = false
                    }, 4000);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper-no4 {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .send {
        --hue: 365deg;
        position: relative;
        width: 50px;
        height: 50px;
        perspective: 66rem;
        transform-style: preserve-3d;
        cursor: pointer;

        * {

            &,
            &::before,
            &::after {
                transition: all 1s;
            }
        }

        .rotate {
            width: 100%;
            height: 100%;
            position: relative;
            transform-style: preserve-3d;

            .move {
                transform-style: preserve-3d;
                width: 100%;
                height: 100%;
                position: relative;

                .part {
                    position: absolute;
                    transform-style: preserve-3d;
                    width: 100%;
                    height: 100%;

                    &::before,
                    &::after {
                        content: 'SEND';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        border-radius: 5px;
                        align-items: center;
                        justify-content: center;
                        background-color: hsl(var(--hue), 70%, 75%);
                        font-size: 1rem;
                        font-weight: bold;
                        color: rgba(255, 255, 255, 0.8);
                    }

                    &.left {
                        &::before {
                            transform-origin: 50% 25%;
                            clip-path: polygon(0 0, 100% 0, 100% 1px, 0 calc(50% + 1px));
                        }

                        &::after {
                            clip-path: polygon(0 50%, 100% 0, 100% 1px, 1px 100%, 0 100%);
                        }
                    }

                    &.right {
                        &::before {
                            clip-path: polygon(0 100%, 100% 0, 100% 1px, calc(50% + 1px) 100%, 50% 100%);
                        }

                        &::after {
                            transform-origin: 75% 50%;
                            clip-path: polygon(100% 0, 50% 100%, 100% 100%);
                        }
                    }
                }
            }
        }
    }


    .checked {
        &.send {
            .rotate {
                transform: rotate3d(1, -0.2, 0.2, 65deg);

                .move {
                    animation: fly 3s cubic-bezier(0.72, -0.23, 0.35, 1.03) 1s both;
                }

                .part {
                    &.left {
                        transform: rotate3d(-1, 1, 0, 60deg);

                        &::before {
                            transform: rotate3d(-1, 0.5, 0, -60deg);
                        }

                        &::after {
                            background-color: hsl(var(--hue), 50%, 70%);
                        }
                    }

                    &.right {
                        transform: rotate3d(-1, 1, 0, -60deg);

                        &::before {
                            background-color: hsl(var(--hue), 40%, 60%);
                        }

                        &::after {
                            transform: rotate3d(-0.25, 0.5, 0, 100deg);
                        }
                    }
                }
            }
        }
    }
}


@keyframes fly {
    0% {
        transform: translate3D(0, 0, 0rem);
    }

    50% {
        transform: translate3D(400rem, -400rem, 0rem);
    }

    50.00001% {
        transform: translate3D(-100rem, 100rem, 0rem);
    }

    100% {
        transform: translate3D(0rem, 0rem, 0rem);
    }
}
</style>