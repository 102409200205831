<template>
    <div class="full-card">
        <div class="full-card-close" @click="closePreview()"></div>
        <div class="full-card-content">
            <el-carousel v-if="imgList.length > 0" :indicator-position="'none'" :interval="5000" arrow="always">
                <el-carousel-item v-for="item in imgList" :key="item">
                    <img v-lazy="getUserHeadImg(item)" />
                </el-carousel-item>
            </el-carousel>
            <div class="full-card-content__inner" :class="{ fullsc: data ? !data.hasImg : true }">
                <div class="inner-wrap">
                    <h1>{{ data ? data.title : '--' }}
                        <div class="essay-info">
                            <h5>{{ data ? $moment(data.CreateTime).format('YYYY/MM/DD') : '--' }}</h5>
                            <h5>{{ data ? $moment(data.CreateTime).format('HH : mm') : '--' }}</h5>
                            <h5>{{ data ? data.author : '--' }}</h5>
                        </div>

                    </h1>

                    <p>{{ data ? data.context : '' }}
                    </p>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    hasImg: false,
                    imgId: '',
                    title: '',
                    context: '',
                    CreateTime: '',
                    author: ''
                }
            }
        },
    },
    methods: {
        getUserHeadImg(item) {
            return `${this.$weacg.BaseUrl}/CompressImage/${item}`;
        },
        closePreview() {
            this.$emit('close')
        }
    },
    computed: {
        imgList() {
            if (this.data ? this.data.hasImg : false) {
                return this.data.imgId.split("|")
            }
            else {
                return []
            }
        }

    }
}

</script>

<style lang="scss"  scoped>
// ::-webkit-scrollbar {

//     width: 0px;
//     height: 0px;
//     /* Chrome Safari */

// }

@keyframes view-card__close--in {
    0% {
        transform: translate(0, -100%);
    }

    100% {
        transform: translate(0, 0%);
    }
}

@keyframes view-card__close--out {
    0% {
        transform: translate(0, 0%);
    }

    100% {
        transform: translate(0, -100%);
    }
}

@keyframes view-card__card--in {
    0% {
        transform: translate(100%, 38%);
    }

    80% {
        opacity: 1
    }

    100% {
        transform: translate(-50%, 38%);
    }
}

@keyframes view-card__card--out {
    0% {
        transform: translate(-50%, 38%);
    }

    0%,
    20% {
        opacity: 1
    }

    100% {
        transform: translate(100%, 38%);
        opacity: 0
    }
}

@media screen and (min-width: 1000px) {

    // ::-webkit-scrollbar {

    //     width: 0px;
    //     height: 0px;
    //     /* Chrome Safari */

    // }

    .el-carousel {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 0;

        /deep/.el-carousel__container {
            height: 100vh
        }
    }

    // .noimg {
    //     background: gray;
    // }


    .full-card {
        background-image: url('https://picsum.photos/1920/1080');
        background-repeat: no-repeat;
        background-size: cover;

        &-close {
            width: 56px;
            height: 56px;
        }

        &-content {
            &__inner {
                height: 100vh;
                width: 90%;
                margin: 0 auto;
            }

        }
    }

    .inner-wrap {
        height: 100vh;
        display: block;
        color: white;

        h1 {
            display: block;
            margin-top: 10vh;
            position: sticky;
            top: 1rem;
            font-size: 6rem;
            // background-color: white;
            // background: linear-gradient(to bottom, white 50%, #fffefed3 80%, #fffefea3);
            // box-shadow: 0px 1px #cfcece;
            padding-bottom: 1rem;
            text-align: left;
            font-weight: bold;
            //max-width: 900px;
            border-radius: 6px 6px 0px 0px;

            .essay-info {
                margin: 2rem 0 0 1rem;


                display: flex;
                justify-content: space-around;
                align-items: flex-start;
                background: transparent;
                flex-direction: column;

                h5 {
                    width: 33%;
                    font-size: small;
                    text-align: left;
                }
            }

        }

        p {
            position: absolute;
            bottom: 10vh;
            margin: 0 0 0 1rem;
            /* margin-top: 50vh; */
            max-width: 900px;
            max-height: 10vh;
            border-radius: 6px;
            overflow-y: auto;
            background: #00000073;
            letter-spacing: 1px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .fullsc {
        height: 100vh !important;
    }

    .el-carousel {
        height: 50vh;

        /deep/.el-carousel__container {
            height: 50vh
        }
    }

    .full-card {
        background-color: white;

        &-close {
            width: 26px;
            height: 26px;
        }

        &-content {
            &__inner {
                height: 50vh;
            }

        }
    }

    .inner-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem 1rem 1rem;

        h1 {
            width: calc(100% - 2rem);
            display: block;
            margin-top: 2rem;
            position: sticky;
            top: 0;
            background-color: white;
            box-shadow: 0px 1px #cfcece;
            margin-bottom: 1rem;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            display: -webkit-box;

            .essay-info {
                margin-top: 1rem;
                margin-bottom: 1rem;
                display: flex;
                justify-content: space-around;
                align-items: center;

                h5 {
                    width: 33%;
                    font-size: small;
                    text-align: center;
                }
            }

        }

        P {
            width: calc(100% - 2rem);
        }
    }
}

%view-card--pseudo-close {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgb(255, 255, 255);
    transform: translate(-50%, -50%) rotate(45deg);
}

.full-card {
    $view-card__close--size: 40%;
    $view-card__close--thickness: 2px;
    $view-card--animation-timing: .5s;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 100vh;
    left: 0;
    z-index: 1000;
    transform: scale(0);
    opacity: 0;
    transition: all .3s ease-in-out;


    &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 50%;

        opacity: .7;
        background-color: #475669;
        z-index: 1000;
        animation: view-card__close--in $view-card--animation-timing ease 1;

        &:before {
            @extend %view-card--pseudo-close;
            width: $view-card__close--thickness;
            height: $view-card__close--size;
        }

        &:after {
            @extend %view-card--pseudo-close;
            height: $view-card__close--thickness;
            width: $view-card__close--size;
        }

        &:active {
            transform: scale(0.8);
        }
    }

    &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__inner {
            display: flex;
            justify-content: center;
            position: relative;

            .inner-wrap {
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                height: calc(100% - 1rem);
                padding-bottom: 1rem;


                h1,
                p {
                    font-family: Arial, Helvetica, sans-serif;
                    word-break: break-all;
                    //line-height: 2rem;
                    

                }

                h1{
                    padding: 1rem 1rem 0 1rem;
                }

                p {
                    padding: 1rem;
                    font-size: 1rem;
                    line-height: 1.5rem;
                }

                p::first-letter {
                    font-size: 2rem;
                }
            }


        }
    }
}


.el-carousel__item img {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
    background: gray;
}

.el-carousel__item img[lazy='loaded'] {
    object-fit: cover;
    background-color: #c3c3c3;
}

.el-carousel__item img[lazy='loading'] {
    object-fit: none;
    background-color: #f1f1f1;
}

.el-carousel__item img[lazy='error'] {
    object-fit: none;
    background-color: #767676;
}
</style>