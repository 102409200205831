/**
 * @name 内存存储
 */
 class MemoryStorage {
    /**
     * @name 构造方法
     */
    constructor() {
      this.data = {}
    }
    /**
     * @name 保存
     * @param name 键名
     * @param value 键值
     */
    set(name, value) {
      this.data[name] = value
    }
    /**
     * @name 读取
     * @param name 键名
     */
    get(name) {
      return this.data[name]
    }
    /**
     * @name 删除
     * @param name 键名
     */
    remove(name) {
      delete this.data[name]
    }
    /**
     * @name 判断是否包含
     * @param name 键名
     */
    contains(name) {
      return name in this.data
    }
    /**
     * @name 清空
     */
    clear() {
      this.storage.clear()
    }
  }

  export default MemoryStorage;
  