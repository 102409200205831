const isElementNotInViewport = function(el) {
    let rect = el.getBoundingClientRect()
    return (
        rect.top >=
            (window.innerHeight || document.documentElement.clientHeight) ||
        rect.bottom <= 0
    )
}

const isNotEmptyStr = function(s) {
	if (typeof s == 'string' && s.length > 0) {
        return true
	}
	return false
}

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCENn+wMlc7Cj+OUlReQps6zSu5xP0sWLH2hV2EqWZ1q4Gs1aYQfta3lQUADI/WJok8s267JT9lxVIdfYAu2dUqKa5Q9VUazX4QMSVNxo/GEx1Csjlt7B0IipoqZLRBdDpfduiSN3MFL+A4l5Ry0gNRZLxOyyrLlMt1ktXqPKizrwIDAQAB'

export { isElementNotInViewport,publicKey,isNotEmptyStr }
