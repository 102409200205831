<template>
    <div class="float-menu-wrap" @click="showMenu" v-clickoutside="closeMenu">
        <div id="float-menu">
            <p class="birth-info">Birth on 2022/06/29</p>
            <ul>
                <li v-for="(item, index) in menuList" :key="index">
                    <a @click="item.callback()">{{ item.name }}</a>
                </li>
            </ul>
        </div>
        <a id="toggle" ><span></span></a>
    </div>
</template>

<script>
import { toggleClass, removeClass } from '@/utils/dom'
import Clickoutside from 'element-ui/src/utils/clickoutside'
export default {
    name: 'weacgFloatMenu',
    directives: { Clickoutside },
    props: {
        menuList: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        showMenu() {
            const dom = document.getElementById('toggle');
            const dom1 = document.getElementById('float-menu');
            toggleClass(dom, 'on')
            toggleClass(dom1, 'on')
            return false
        },
        closeMenu() {
            const dom = document.getElementById('toggle');
            const dom1 = document.getElementById('float-menu');
            removeClass(dom, 'on')
            removeClass(dom1, 'on')
        }
    }
}
</script>

<style lang="scss" scoped>
.float-menu-wrap {
    height: 40px;
}

#toggle {
    display: block;
    width: 28px;
    height: 40px;
    margin: 0px auto;
    position: relative;
}

#toggle span:after,
#toggle span:before {
    content: "";
    position: absolute;
    left: 0;
    top: -9px;
}

#toggle span:after {
    top: 9px;
}

#toggle span {
    position: absolute;
    display: block;
    transform: translateY(18px);
}

#toggle span,
#toggle span:after,
#toggle span:before {
    width: 100%;
    height: 5px;
    background-color: #888;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px;
}

/* on activation */
#toggle.on span {
    background-color: transparent;
}

#toggle.on span:before {
    transform: rotate(45deg) translate(5px, 5px);
}

#toggle.on span:after {
    transform: rotate(-45deg) translate(7px, -8px);
}

#float-menu {
    &.on {
        opacity: 1;
        visibility: visible;
    }

    .birth-info {
        //font-family: 'Indie Flower';
    }

}

/* menu appearance*/
#float-menu {
    z-index: 100;
    position: absolute;
    color: #999;
    width: 200px;
    bottom: 60px;
    left: calc(50vw - 110px);
    padding: 10px;
    margin: auto;
    font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;
    text-align: center;
    border-radius: 10px;
    background: white;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
    /* just for this demo */
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s;
}

#float-menu:after {
    position: absolute;
    bottom: -15px;
    left: 95px;
    content: "";
    display: block;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid white;
}

ul {
    max-height: 200px;
    overflow-y: auto;
}

ul,
li,
li a {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
}

li a {
    border-radius: 5px;
    padding: 5px;
    color: #888;
    text-decoration: none;
    transition: all .2s;
}

li a:hover,
li a:focus {
    background: #f78989;
    color: #fff;
}
</style>