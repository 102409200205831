import activityText from './activityText/activityText'
import countUP from './countUp/countUp'
import timeLine from './timeLine/timeLine'
import easyReadTime from './easyReadTime/easyReadTime'
import animButton from './animButton/animButton'
import wishDialog from './wishDialog/wishDialog'
import upload from './upload/upload'
import letterDialog from './letterDialog/letterDialog'
import empty from './empty/empty'
import textBtn from './textBtn/textBtn'
import timeLine2 from './timeLine2/timeLine2'
import timeLineEmpty from './timeLineEmpty/timeLineEmpty'
import weacgLoading from './loading/Loading'
import audioPlayer from './audioPlayer/audioPlayer'
import message from './message/message'
import sendButton from './sendButton/sendButton'
import floatMenu from './floatMenu/floatMenu'

const weAcgComponents = [
    { name: easyReadTime.name, component: easyReadTime },
    { name: timeLine.name, component: timeLine },
    { name: activityText.name, component: activityText },
    { name: countUP.name, component: countUP },
    { name: animButton.name, component: animButton },
    { name: wishDialog.name, component: wishDialog },
    { name: upload.name, component: upload },
    { name: letterDialog.name, component: letterDialog },
    { name: empty.name, component: empty },
    { name: textBtn.name, component: textBtn },
    { name: timeLine2.name, component: timeLine2 },
    { name: timeLineEmpty.name, component: timeLineEmpty },
    { name: weacgLoading.name, component: weacgLoading },
    { name: audioPlayer.name, component: audioPlayer },
    { name: message.name, component: message },
    { name: sendButton.name, component: sendButton },
    { name: floatMenu.name, component: floatMenu }
]

const install = _Vue => {
    weAcgComponents.forEach(item => _Vue.component(item.name, item.component))
}

export default install
