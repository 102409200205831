<template>
    <el-dialog :visible.sync="visible" :width="dialogWidth" :show-close="showClose" @open="open" @closed="close"
        destroy-on-close center id="wish_dialog">
        <div slot="title" v-if="type === 'diary'" class="sercet">
            <el-switch v-model="form.hasSercet" active-text="私密" active-color="#b7b7b7" inactive-color="#ff4949"
                inactive-text="公开">
            </el-switch>
        </div>
        <div class="box">
            <div class="title">{{ title }}</div>


            <div v-if="type === 'wish'" class="input">
                <!-- <label for="pass">Password</label> -->
                <input placeholder="你的生日愿望" v-model="form.wish" type="text" />
                <span class="spin"></span>
            </div>


            <div v-if="type === 'whisper'" class="input">
                <!-- <label for="pass">Password</label> -->
                <input placeholder="说句悄悄话" v-model="form.whisper" type="text" />
                <span class="spin"></span>
            </div>


            <div v-if="type === 'diary' && form.hasSercet" class="input">
                <!-- <label for="pass">Password</label> -->
                <input placeholder="设置密码" v-model="form.diarySercet" type="password" />
                <span class="spin"></span>
            </div>

            <div v-if="type === 'diary' || type === 'timeline'" class="input">
                <!-- <label for="pass">Password</label> -->
                <input :placeholder="type === 'diary' ? '日记标题' : '时间轴标题(可以留空喽)'" v-model="form.title" type="text" />
                <span class="spin"></span>
            </div>

            <div v-if="type === 'diary'" class="input">
                <!-- <label for="pass">Password</label> -->
                <input placeholder="摘要" v-model="form.desc" type="text" />
                <span class="spin"></span>
            </div>

            <div v-if="type === 'diary' || type === 'timeline'" class="textarea">
                <!-- <label for="pass">Password</label> -->
                <textarea :placeholder="type === 'diary' ? '日记内容' : '时间轴内容'" v-model="form.context" type="text" />
                <span class="spin"></span>
            </div>

            <div v-if="type === 'unlock'" class="input">
                <!-- <label for="pass">Password</label> -->
                <input placeholder="请输入解锁密码" v-model="form.unLockSercet" type="password" />
                <span class="spin"></span>
            </div>

            <div class="switch" v-if="type !== 'unlock' && type !== 'wish'">
                <!-- <input placeholder="密钥" v-model="form.wishSercet" type="text" /> -->
                <el-switch v-model="form.wishSercet" active-color="#1296db" inactive-color="#ff4949">
                </el-switch>
                <weacg-icon :size="20" style="margin-left: 1rem;" :iconName="form.wishSercet ? 'male' : 'female'">
                </weacg-icon>
            </div>

            <weacg-upload v-if="type !== 'unlock' && type !== 'whisper'" ref="upload" :doubleRuleCheck="doubleRuleCheck"
                @upload-sucess="submit" :limitCount="type === 'timeline' ? 9 : 1"></weacg-upload>

            <div class="button login" :style="type === 'unlock' ? 'margin-top:40px' : ''">
                <button @click="setWish">
                    <span>{{ buttonText }}</span> <i class="fa fa-check"></i>
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import wish from '@/assets/imgs/birthday/wish.png'
import diary from '@/assets/imgs/birthday/diary.png'
import time from '@/assets/imgs/birthday/time.png'
import whisper from '@/assets/imgs/birthday/whisper.png'
export default {
    name: 'weacgWishDialog',
    props: {
        showClose: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            dialogWidth: '80%',
            form: {
                wishSercet: false,
                hasSercet: true,
                wish: '',
                whisper: '',
                author: '颍',
                unLockDiaryId: '',
                context: '',
                title: '',
                unLockSercet: '',
                diarySercet: '',
                desc: ''
            },
            title: '',
            diaryId: '',
            type: 'wish'
        }
    },
    mounted() {
        this.setDialogWidth()
        this.$bus.$on('resize', () => {
            this.setDialogWidth()
        })
    },
    computed: {
        buttonText() {
            if (this.type === 'wish') {
                return '许下愿望'
            }
            if (this.type === 'diary') {
                return '写下日记'
            }
            if (this.type === 'timeline') {
                return '留下时光'
            }
            if (this.type === 'unlock') {
                return '解锁秘密'
            }
            if (this.type === 'whisper') {
                return '写悄悄话'
            }
            return '许下愿望'
        }
    },
    methods: {
        open() {
            switch (this.type) {
                case 'wish':
                    this.form = {
                        wishSercet: false,
                        wish: '',
                        author: '颍',
                    }
                    this.title = 'your wish'
                    document
                        .getElementById('wish_dialog')
                        .getElementsByClassName(
                            'el-dialog'
                        )[0].style.backgroundImage = `url(${wish})`
                    break
                case 'whisper':
                    this.form = {
                        wishSercet: false,
                        whisper: '',
                        author: '颍',
                        unLockDiaryId: ''
                    }
                    this.title = 'your whisper'
                    document
                        .getElementById('wish_dialog')
                        .getElementsByClassName(
                            'el-dialog'
                        )[0].style.backgroundImage = `url(${whisper})`
                    break
                case 'diary':
                    this.form = {
                        title: '',
                        hasSercet: true,
                        wishSercet: false,
                        context: '',
                        author: '颍',
                        diarySercet: '',
                        desc: ''
                    }
                    document
                        .getElementById('wish_dialog')
                        .getElementsByClassName(
                            'el-dialog'
                        )[0].style.backgroundImage = `url(${diary})`
                    this.title = 'your diary'
                    break
                case 'timeline':
                    this.form = {
                        title: '',
                        wishSercet: false,
                        context: '',
                        author: '颍'
                    }
                    this.title = 'your time'
                    document
                        .getElementById('wish_dialog')
                        .getElementsByClassName(
                            'el-dialog'
                        )[0].style.backgroundImage = `url(${time})`
                    break
                case 'unlock':
                    this.form = {
                        unLockSercet: ''
                    }
                    this.title = 'unLock Diary'
                    break
            }
        },
        close() {
            console.log('reset')
            this.form = {
                wishSercet: false,
                hasSercet: true,
                wish: '',
                author: '颍',
                unLockDiaryId: '',
                context: '',
                title: '',
                unLockSercet: '',
                diarySercet: '',
                desc: ''
            }
            this.diaryId = ''
            this.type = 'wish'
        },
        setWish() {
            if (this.type !== 'unlock' && this.type !== 'whisper') {
                this.$refs.upload.submit()
            } else if (this.type === 'whisper') {
                if (this.isEmptyStr(this.form.whisper)) {
                    this.$notify({
                        title: '消息',
                        message: '悄悄话不能为空哦',
                        position: 'top-right'
                    })
                } else {
                    this.submit()
                }
            } else {
                this.unLockDiary()
            }
        },
        isEmptyStr(s) {
            if (typeof s === 'string' && s.length > 0) {
                return false
            }
            return true
        },
        doubleRuleCheck() {
            if (this.form.wishSercet) {
                this.form.author = 'A2B9S'
            }
            if (this.type === 'wish') {
                if (this.isEmptyStr(this.form.wish)) {
                    return '没写愿望可不行哦'
                }
                if (
                    !this.isEmptyStr(this.form.wish) &&
                    this.form.wish.length < 10
                ) {
                    return '愿望多写一点吧'
                }
                return ''
            }
            if (this.type === 'diary' || this.type === 'timeline') {
                if (this.type === 'diary') {
                    if (this.form.hasSercet) {
                        if (this.isEmptyStr(this.form.diarySercet)) {
                            return '请设定日记密码哦'
                        }
                    }
                    if (
                        !this.isEmptyStr(this.form.desc) &&
                        this.form.desc.length > 10
                    ) {
                        return '摘要不要超过10个字哦'
                    }
                }
                if(this.type === 'timeline' && this.isEmptyStr(this.form.title)){
                    this.form.title = '零散的记录'
                }
                if (this.isEmptyStr(this.form.title)) {
                    return '请写上标题哦'
                }
                if (this.isEmptyStr(this.form.context)) {
                    return '没写内容可不行哦'
                }
                return ''
            }
        },
        sercetCheck() {
            if (this.isEmptyStr(this.form.unLockSercet)) {
                return '请输入解锁密码'
            }
            return ''
        },
        submit(res) {
            if (this.type === 'whisper') {
                this.form.unLockDiaryId = this.diaryId
            }
            var postData = {
                baseData: this.form,
                FileNames: res ? res.data.response : []
            }
            if (this.type !== 'unlock') {
                this.axios
                    .post(`${this.type}/CreateForm`, postData)
                    .then(response => {
                        if (this.type === 'whisper') {
                            if (!this.isEmptyStr(response.data.extra)) {
                                this.$bus.$emit('passget', {
                                    id: this.form.unLockDiaryId,
                                    pass: response.data.extra
                                })
                                this.visible = false
                                this.$bus.$emit('closeLoading')
                                this.$emit('load-data')
                            } else {
                                this.$bus.$emit('closeLoading')
                                this.$notify({
                                    title: '消息',
                                    message: response.data.msg,
                                    position: 'top-right'
                                })
                                this.done()
                            }
                        } else {
                            this.$bus.$emit('closeLoading')
                            this.$notify({
                                title: '消息',
                                message: response.data.msg,
                                position: 'top-right'
                            })
                            this.done()
                        }
                    })
                    .catch(() => {
                        this.$bus.$emit('closeLoading')
                        this.$notify({
                            title: '消息',
                            message: '出错喽,找他看看吧',
                            position: 'top-right'
                        })
                    })
            } else {
                this.$notify({
                    title: '消息',
                    message: '可能是受到了万有引力干扰，出错喽',
                    position: 'top-right'
                })
            }
        },
        unLockDiary() {
            try {
                this.$bus.$emit('showLoading')
                this.axios
                    .get(
                        `Diary/GetDd?did=${this.diaryId}&sercet=${this.form.unLockSercet}`
                    )
                    .then(res => {
                        if (res.data.success === true) {
                            this.$bus.$emit('closeLoading')
                            this.openLetter(res.data.response)
                        } else {
                            this.$bus.$emit('closeLoading')
                            this.$notify({
                                title: '消息',
                                message: res.data.msg,
                                position: 'top-right'
                            })
                        }
                    })
            } catch {
                this.$bus.$emit('closeLoading')
            }
        },
        done() {
            this.$emit('load-data')
            this.visible = false
        },
        openLetter(context) {
            this.$emit('open-letter', context)
            this.visible = false
        },

        setDialogWidth() {
            var width = document.body.clientWidth
            if (width > 900) {
                this.dialogWidth = '600px'
            } else {
                this.dialogWidth = '90%'
            }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-switch__label.is-active {
    color: #ed2553;
}

/deep/.el-dialog,
.el-pager li {
    background-color: #f6f6f6;
    background-repeat: no-repeat;
    // background-position-x: 90%;
    // background-position-y: 5%;
    background-position: top;
}

/deep/.el-dialog__body {}

.box {
    position: relative;
    top: 0;
    opacity: 1;
    padding: 60px 20px 40px 20px;
    border-radius: 10px;
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    z-index: 5;
}

.textarea {
    margin-top: 30px;

    textarea {
        width: calc(~'100% - 20px');
        height: 70px;
        resize: none;
        outline: none;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: #606266;
        background-color: #f6f6f6;
        border: 1px solid rgba(0, 0, 0, 0.1);
        -webkit-user-select: text !important;
        padding: 10px;
    }
}

.title {
    width: 100%;
    float: left;
    line-height: 46px;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #ed2553;
    position: relative;

    &:before {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -45px;
        background: #ed2553;
    }
}

.input {
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);

    label,
    input,
    .spin {
        transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
}

.button {
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);

    button .button.login button i.fa {
        transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
}

.button.login button {
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input {
    width: 100%;
    outline: none;
    -webkit-user-select: text !important;

    label,
    input,
    .spin {
        width: 100%;
    }
}

.switch {
    width: 100%;
    margin-top: 30px;
    height: 20px;
    display: flex;
}

.sercet {
    width: 100%;
    position: absolute;
    left: 0;

}

.button {
    width: 100%;
    outline: none;
    -webkit-user-select: text !important;

    button {
        width: 100%;
    }
}

.input,
.button {
    outline: none;
    margin-top: 30px;
    height: 70px;
    -webkit-user-select: text !important;
}

.input {
    position: relative;
    outline: none;
    -webkit-user-select: text !important;

    input {
        position: relative;
        outline: none;
    }
}

.button {
    position: relative;

    button {
        position: relative;
    }
}

.input {
    input {
        height: 60px;
        top: 10px;
        border: none;
        background: transparent;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 300;
        -webkit-user-select: text !important;
    }

    label {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 300;
    }
}

.button button {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300;
}

.input {

    &:before,
    .spin {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &:before {
        content: '';
        background: rgba(0, 0, 0, 0.1);
        z-index: 3;
    }

    .spin {
        background: #ed2553;
        z-index: 4;
        width: 0;
    }
}

.input label {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    cursor: pointer;
    line-height: 60px;
}

.button {
    &.login {
        width: 60%;

        button {
            width: 100%;
            line-height: 64px;
            left: 0%;
            background-color: transparent;
            border: 3px solid rgba(0, 0, 0, 0.1);
            font-weight: 900;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.2);
        }
    }

    button {
        width: 100%;
        line-height: 64px;
        left: 0%;
        background-color: transparent;
        border: 3px solid rgba(0, 0, 0, 0.1);
        font-weight: 900;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.2);
    }

    &.login {
        margin: 20px auto 0 auto;
    }

    margin-top: 20px;

    button {
        background-color: #fff;
        color: #ed2553;
        border: none;
    }

    &.login button {
        &.active {
            border: 3px solid transparent;
            color: #fff !important;

            span {
                opacity: 0;
                transform: scale(0);
                -webkit-transform: scale(0);
                -ms-transform: scale(0);
            }

            i.fa {
                opacity: 1;
                transform: scale(1) rotate(-0deg);
                -webkit-transform: scale(1) rotate(-0deg);
                -ms-transform: scale(1) rotate(-0deg);
            }
        }

        i.fa {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            line-height: 60px;
            transform: scale(0) rotate(-45deg);
            -webkit-transform: scale(0) rotate(-45deg);
            -ms-transform: scale(0) rotate(-45deg);
        }

        &:hover {
            color: #ed2553;
            border-color: #ed2553;
        }
    }

    margin: 40px 0;
    overflow: hidden;
    z-index: 2;

    button {
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
}
</style>
