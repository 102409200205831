<template>
    <div class="content">
        <div class="content__container">
            <p class="content__container__text">
                正在
            </p>

            <ul class="content__container__list">
                <li class="content__container__list__item">整理你的日记 !</li>
                <li class="content__container__list__item">聆听你的愿望 !</li>
                <li class="content__container__list__item">回想你的时光 !</li>
                <li class="content__container__list__item">填满你的背囊 !</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'weacgActivityText'
}
</script>

<style lang="scss" scoped>
.content {
    overflow: hidden;

    font-family: 'Lato', sans-serif;
    font-size: 20px;
    line-height: 40px;
    color: #ecf0f1;

    &__container {
        font-weight: 600;
        overflow: hidden;
        height: 40px;
        padding: 0 20px;

        &__text {
            display: inline;
            float: left;
            margin: 0;
        }

        &__list {
            margin-top: 0;
            padding-left: 50px;
            text-align: left;
            list-style: none;

            -webkit-animation-name: change;
            -webkit-animation-duration: 10s;
            -webkit-animation-iteration-count: infinite;
            animation-name: change;
            animation-duration: 15s;
            animation-iteration-count: infinite;

            &__item {
                line-height: 40px;
                margin: 0;
            }
        }
    }
}

@-webkit-keyframes opacity {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes change {
    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}

@-o-keyframes opacity {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@-o-keyframes change {
    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}

@-moz-keyframes opacity {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@-moz-keyframes change {
    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}

@keyframes opacity {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes change {
    0%,
    12.66%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    16.66%,
    29.32% {
        transform: translate3d(0, -25%, 0);
    }
    33.32%,
    45.98% {
        transform: translate3d(0, -50%, 0);
    }
    49.98%,
    62.64% {
        transform: translate3d(0, -75%, 0);
    }
    66.64%,
    79.3% {
        transform: translate3d(0, -50%, 0);
    }
    83.3%,
    95.96% {
        transform: translate3d(0, -25%, 0);
    }
}
</style>
