export default [
    {
        path: '/birthday',
        name: 'birthday',
        component: () => import('./birthday')
    },
    {
        path: '/cake',
        name: 'cake',
        component: () => import('./cake')
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: () => import('./welcome')
    }
]
