<template>
    <div class="weacg_upload">
        <el-upload style="margin: 0 auto" action="none" :headers="upLoadHeaders" ref="upload" list-type="picture"
            :file-list="imgFiles" :on-preview="handlePictureCardPreview" :on-error="onError" :auto-upload="false"
            :http-request="appendImage" :limit="limitCount" :class="{ hide: hideUpload }"
            accept="image/jpeg,image/png,image/jpg" multiple :on-exceed="handleExceed">
            <el-button size="small">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">{{`PS:单个照片不要超过5MB，总大小不要超过30M哦，最多支持上传${limitCount}张图片`}}</div>
        </el-upload>
        <el-dialog append-to-body :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'weacgUpload',
    props: {
        doubleRuleCheck: {
            type: Function
        },
        limitCount: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            upLoadHeaders: { 'Content-Type': 'multipart/form-data' },
            uploadUrl: 'http://localhost:8081/api/Img/images/Upload/Pic',
            imgFiles: [],
            fileData: null,
            dialogImageUrl: '',
            dialogVisible: false,
            hideUpload: false
        }
    },
    methods: {
        onError() {
            console.log('上传失败')
        },
        handleExceed() {
            this.$notify({
                title: '消息',
                message: `最多上传${this.limitCount}张图片哦`,
                position: 'top-right'
            })
        },
        // change() {
        //     var flag =
        //         this.limitCount === -1
        //             ? false
        //             : this.$refs.upload.uploadFiles.length >= this.limitCount
        //                 ? true
        //                 : false
        //     if (flag === false) {
        //         setTimeout(() => {
        //             this.hideUpload = false
        //         }, 1000)
        //     } else {
        //         this.hideUpload = true
        //     }
        // },
        appendImage(param) {
            this.fileData.append('files', param.file) //element submit 循环插入图片
        },
        // handleRemove(file, fileList) {
        //     this.change()
        // },
        isEmptyStr(s) {
            if (typeof s === 'string' && s.length > 0) {
                return false
            }
            return true
        },
        submit() {
            if (this.doubleRuleCheck) {
                var result = this.doubleRuleCheck()
                if (!this.isEmptyStr(result)) {
                    this.$notify({
                        title: '消息',
                        message: result,
                        position: 'top-right'
                    })
                    return
                }
            }
            if (this.$refs.upload.uploadFiles.length > 0) {
                try {
                    this.$bus.$emit('showLoading')
                    this.fileData = new FormData()
                    this.$refs.upload.submit()
                    this.axios
                        .post('Img/images/Upload/Pic', this.fileData)
                        .then(res => {
                            if (res.data.success) {
                                this.$emit('upload-sucess', res)
                            } else {
                                this.$bus.$emit('closeLoading')
                                this.$notify({
                                    title: '消息',
                                    message: res.data.msg,
                                    position: 'top-right'
                                })
                            }
                        })
                        .catch(() => {
                            console.log(this.fileData)
                            this.$bus.$emit('closeLoading')
                            this.$notify({
                                title: '消息',
                                message: '出错喽,看下图片是不是太大了呢',
                                position: 'top-right'
                            })
                        })
                } catch (e) {
                    this.$bus.$emit('closeLoading')
                    this.$notify({
                        title: '消息',
                        message: '出错喽,看下图片是不是太大了呢',
                        position: 'top-right'
                    })
                }
            } else {
                this.$emit('upload-sucess')
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.hide .el-upload--picture-card {
    display: none;
}

/deep/.el-upload-list__item-thumbnail {
    object-fit: cover;
}

/deep/.el-icon-document {
    display: none;
}

.weacg_upload {
    position: relative;
    width: 100%;
    padding: 20px 0;
    // height: 163px;
}
</style>
