<template>
    <div
        class="weacg_icon"
        :style="{
            padding: paddingsize + 'px',
            width: size + 'px',
            height: size + 'px'
        }"
    >
        <svg
            :style="{
                width: size - 2 * paddingsize + 'px',
                height: size - 2 * paddingsize + 'px'
            }"
            :fill="iconColor"
            class="svg-icon"
        >
            <use :xlink:href="iconNameCp" />
        </svg>
    </div>
</template>
<script>
export default {
    name: 'svgIcon',
    props: {
        // 图标类型
        iconName: {
            type: String,
            required: true
        },
        // 图标颜色
        iconColor: {
            type: String,
            default: '#666'
        },
        size: {
            type: Number,
            default: 32
        },
        paddingsize: {
            type: Number,
            default: 0
        }
    },
    computed: {
        iconNameCp() {
            return `#icon-${this.iconName}`
        }
    }
}
</script>
