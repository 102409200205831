<template>
    <div class="time_line2">
        <h1 class="title">Time Line</h1>
        <p v-if="timeData.length > 0" class="leader">
            Time flows across the world.
        </p>
        <section class="timeline" v-if="timeData.length > 0">
            <div class="container">
                <div :id="`timeline-item${index}`" class="timeline-item" v-for="(item, index) in timeData" :key="index"
                    @click="goTimePreview(item)">
                    <div class="timeline-img"></div>

                    <div id="timeline-content" :class="
                        item.hasImg
                            ? 'timeline-content timeline-card js--fadeInLeft'
                            : 'timeline-content  js--fadeInLeft'
                    ">
                        <div v-if="item.hasImg" class="timeline-img-header">
                            <img v-lazy="getUserHeadImg(item)" />
                            <div class="mask-title">{{ item.title }}</div>
                        </div>
                        <h2 v-else>{{ item.title }}</h2>
                        <div class="date">
                            {{ item.CreateTime | timeParse }}
                        </div>
                        <p class="tm-context">
                            {{ item.context }}
                        </p>
                        <p class="author">{{ `-- ${item.author} ${$moment(item.CreateTime).format('HH : mm')}` }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="empty" v-if="timeData.length === 0">
            <div>
                <weacg-time-line-empty></weacg-time-line-empty>
            </div>
        </section>
        <div v-if="timeData.length > 0" class="pagination">
            <el-pagination small :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next"
                :total="totalCount">
            </el-pagination>
        </div>
        <p v-if="timeData.length > 0" class="leader">
            There is always a longer way to go.
        </p>
        <FullCard :class="{ showfullcard: showPreview }" :data="previewData" @close="showPreview = false"></FullCard>
    </div>
</template>

<script>
import FullCard from './fullCard.vue'

export default {
    name: "weacgTimeLine2",
    props: {
        timeData: {
            type: Array,
            default: () => []
        },
        totalCount: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            baseUrl: this.$weacg.BaseUrl,
            currentPage: 1,
            previewData: null,
            showPreview: false
        };
    },
    filters: {
        timeParse: function (value) {
            var timestamp = new Date(value);
            var day = timestamp.getDate();
            var result = "";
            if (day < 10) {
                result += `0${day}`;
            }
            else {
                result += day;
            }
            var month = timestamp.getMonth();
            switch (month + 1) {
                case 1:
                    result += "Jan";
                    break;
                case 2:
                    result += "Feb";
                    break;
                case 3:
                    result += "Mar";
                    break;
                case 4:
                    result += "Apr";
                    break;
                case 5:
                    result += "May";
                    break;
                case 6:
                    result += "Jun";
                    break;
                case 7:
                    result += "Jul";
                    break;
                case 8:
                    result += "Aug";
                    break;
                case 9:
                    result += "Sep";
                    break;
                case 10:
                    result += "Oct";
                    break;
                case 11:
                    result += "Nov";
                    break;
                case 12:
                    result += "Dec";
                    break;
                default:
                    result += "Azm";
            }
            var year = timestamp.getFullYear();
            result += year;
            return result;
        }
    },
    mounted() { },
    watch: {
        currentPage: {
            handler() {
                this.$emit("timePageChange");
            }
        }
    },
    methods: {
        getUserHeadImg(item) {
            if (item.hasImg) {
                let imgList = item.imgId.split("|")
                return `${this.baseUrl}/CompressImage/${imgList[0]}`;
                // return `${this.baseUrl}/logo/loading1/gif`
            }
            return null;
        },
        goTimePreview(item) {
            if (this.showPreview) {
                return
            }
            this.previewData = item;
            this.showPreview = true
        }
    },
    components: { FullCard }
}
</script>

<style lang="scss" scoped>
$primary: #bdbdbd;
$dark-primary: #bdbdbd;
$light-primary: #bdbdbd;
$text: #ffffff;
$primary-text: #212121;
$secondary-text: #2b1f1f;
$accent: #ff4081;

.pagination {
    max-width: 90%;
    margin: auto;
    padding-bottom: 45px;
}

/deep/.el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-prev {
        background: none !important;
    }

    .btn-next {
        background: none !important;
    }

}

/deep/.el-pager li {
    background: none !important;
}

.time_line2 {
    margin: 10vh 0 0 0;
    background-color: #eee;

    .showfullcard {
        top: 0;
        transform: scale(1);
        opacity: 1;
    }
}

section {
    width: 60vw;
    margin: 0 auto;
}

.empty {
    width: 100%;
}

.title {
    text-align: center;
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 20px;
}

h1 {
    font-size: 200%;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 400;
}

p.leader {
    text-align: center;
    max-width: 90%;
    margin: auto;
    padding-bottom: 45px;
}

header {
    background: $primary;
    color: $text;
    padding: 150px 0;

    p {
        font-family: 'Allura';
        color: rgba(255, 255, 255, 0.2);
        margin-bottom: 0;
        font-size: 60px;
        margin-top: -30px;
    }
}

.timeline {
    position: relative;

    &::before {
        content: '';
        background: $light-primary;
        width: 1px;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}


.timeline-item {
    width: 100%;
    margin-bottom: 70px;

    &:nth-child(even) {
        .timeline-card {

            // margin-right: 60px;
            .date {
                right: auto;
                left: 0;
                text-shadow: 1px 1px #666464;
            }
        }

        .timeline-content {
            float: right;
            // padding: 40px 30px 10px 30px;

            &::after {
                content: '';
                position: absolute;
                border-style: solid;
                width: 0;
                height: 0;
                top: 30px;
                left: -15px;
                border-width: 10px 15px 10px 0;
                border-color: transparent #f5f5f5 transparent transparent;
            }
        }
    }

    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.timeline-content:hover {
    transform: scale(0.95);
}

.timeline-content {
    position: relative;
    width: 40%;
    padding: 10px 30px;
    border-radius: 10px;
    background: #f5f5f5;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
    transition: all .2s ease-in-out;

    h2 {
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tm-context {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
    }

    &::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        right: -15px;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #f5f5f5;
    }

    .author {
        text-align: right;
    }
}

.timeline-img {
    width: 10px;
    height: 10px;
    background: $primary;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -5px;
}

a {
    background: $primary;
    color: $text;
    padding: 8px 20px;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 2px;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.6);

    &:hover,
    &:active,
    &:focus {
        background: darken($primary, 10%);
        color: $text;
        text-decoration: none;
    }
}

.timeline-card {
    .date {
        color: $text  !important;
    }

    width: calc(40% + 60px);
    padding: 0 !important;

    p {
        padding: 0px 20px 0px 20px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
    }

    a {
        margin-left: 20px;
    }
}

.timeline-item {
    .timeline-img-header {
        background-size: cover;
        border-radius: 10px 10px 0 0;
    }
}

.timeline-img-header {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;

    .mask-title {
        color: $text;
        position: absolute;
        bottom: 5px;
        z-index: 0;
        left: 20px;
        font-size: 1.5rem;
        text-shadow: 1px 1px #666464;
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    // .mask-title::after {
    //     content: '';
    //     left: -20px;
    //     top: -0.2rem;
    //     padding: 0 20px;
    //     z-index: -1;
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     background-image: linear-gradient(to right, transparent, #c3c3c3b8, transparent);
    // }

    img {
        width: 100%;
        height: 100%;
    }

    img[lazy='loaded'] {
        object-fit: cover;
        background-color: #c3c3c3;
    }

    img[lazy='loading'] {
        object-fit: none;
        background-color: #ffffff;
    }

    img[lazy='error'] {
        object-fit: none;
        background-color: #767676;
    }


}

blockquote {
    margin-top: 30px;
    color: $secondary-text;
    border-left-color: $primary;
    padding: 0 20px;
}

.date {
    display: inline-block;
    color: $primary-text;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 1000px) {
    section {
        width: 90vw;
    }

    .timeline {
        &::before {
            left: 0px;
        }

        .timeline-img {
            left: 0px;
        }

        .timeline-content {
            max-width: 100%;
            width: auto;
            margin-left: 40px;
        }

        .timeline-item {
            &:nth-child(even) {
                .timeline-content {
                    float: none;
                }

                .timeline-card {
                    margin-right: 0;
                }
            }

            &:nth-child(odd) {
                .timeline-content {
                    &::after {
                        content: '';
                        position: absolute;
                        border-style: solid;
                        width: 0;
                        height: 0;
                        top: 30px;
                        left: -15px;
                        border-width: 10px 15px 10px 0;
                        border-color: transparent #f5f5f5 transparent transparent;
                    }
                }
            }
        }
    }
}
</style>
