<template>
    <div class="card" id="diary_card">
        <div class="imgBox">
            <div class="bark"></div>
            <img
                src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg02.taopic.com%2F190119%2F318758-1Z119112Z096.jpg&refer=http%3A%2F%2Fimg02.taopic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637257513&t=b3753ff6dd43bfa11da5ac047ef59ba2"
            />
        </div>
        <div
            class="detail_wrap"
            style="width: 100%;height:100%;overflow:hidden"
        >
            <div class="details">
                <h4 class="color2">Best Wish to you</h4>
                <p>只管带来故事的种子</p>
                <p>时间会使其发芽</p>
                <p class="text-right">Waiting for you, 颍!</p>
                <p class="text-right">A2B9S</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'weacgEmpty',
    mounted() {},
    methods: {
        setSize() {
            var width = document.body.clientWidth
            var height = document.body.clientHeight
            var rect = width / height
            var root = document.getElementById('action_btn')
            var warp = document.getElementById('anim_btn')
            var content = document.getElementById('diary_card')

            if (rect > 1) {
                root.style.height = `${25 * rect}vh`
                warp.style.height = `${25 * rect}vh`
                content.style.height = `calc(${20 * rect}vh - 70px)`
                console.log(rect)
            } else {
                console.log(rect)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@mixin mq-xs {
    @media (min-width: 320px) {
        @content;
    }
}

@mixin mq-sm {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin mq-md {
    @media (min-width: 720px) {
        @content;
    }
}

@mixin mq-lg {
    @media (min-width: 1000px) {
        @content;
    }
}

::selection {
    background: transparent;
}

h4 {
    font-size: 2vh;
    line-height: 1px;
}

.color1 {
    color: #1bbc9b;
} /*MOUNTAIN MEADOW*/
.color2 {
    color: #c0392b; /*TALL POPPY*/
}

.card {
    color: #013243; /*SHERPA BLUE*/
    position: relative;
    top: 145px;
    left: 50%;
    width: 220px;
    height: 275px;
    max-width: 210px;
    @include mq-md {
        width: 280px;
    }
    background: #e0e1dc;
    transform-style: preserve-3d;
    transform: translate(-50%, -50%) perspective(2000px);
    box-shadow: inset 300px 0 50px rgba(0, 0, 0, 0.5),
        20px 0 60px rgba(0, 0, 0, 0.5);
    transition: 1s;
}

.card:hover {
    transform: translate(-50%, -50%) perspective(2000px) rotate(15deg)
        scale(1.2);
    box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5),
        0 10px 100px rgba(0, 0, 0, 0.5);
}

.detail_wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.card:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    background: #bac1ba;
    transform-origin: bottom;
    transform: skewX(-45deg);
}

.card:after {
    content: '';
    position: absolute;
    top: 0;
    right: -5px;
    width: 5px;
    height: 100%;
    background: #92a29c;
    transform-origin: left;
    transform: skewY(-45deg);
}

.card .imgBox {
    width: 100%;
    height: 100%;
    position: relative;
    transform-origin: left;
    transition: 0.7s;
}

.card .bark {
    position: absolute;
    background: #e0e1dc;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.7s;
}

.card .imgBox img {
    width: 80%;
    height: 70%;
    border-radius: 0px 0px 10px 0px;
}

.card:hover .imgBox {
    transform: rotateY(-135deg);
}

.card:hover .bark {
    opacity: 1;
    transition: 0.6s;
    box-shadow: 300px 200px 100px rgba(0, 0, 0, 0.4) inset;
}

.card .details {
    position: absolute;
    width: 90%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    z-index: -1;
    margin-top: 10%;
}

.card .details p {
    font-family: "FZSJ-TGYBXSJW","Oswald",sans-serif;
    font-size: 1rem;
    transform: rotate(-10deg);
    padding: 15px 0 0 20px;
    line-height: 120%;
}

.card .details h4 {
    text-align: center;
    line-height: 100%;
}

.text-right {
    text-align: right;
}
</style>
