<template>
    <div class="anim_btn" id="anim_btn" :style="diaryData.length > 0 ? 'height:auto' : 'height:455px'">
        <h1 class="btn_title">Diary</h1>
        <div class="row" v-if="diaryData.length > 0">
            <div v-for="(item, index) in diaryData" :key="index" class="example-2 card">
                <div class="wrapper">
                    <img v-lazy="getUserHeadImg(item)" />
                    <div class="header">
                        <div class="date">
                            <span class="day">{{
                                    item.CreateTime | timeParse('day')
                            }}</span>
                            <span class="month">{{
                                    item.CreateTime | timeParse('month')
                            }}</span>
                            <span class="year">{{
                                    item.CreateTime | timeParse('year')
                            }}</span>
                        </div>
                    </div>
                    <div class="data">
                        <div class="content">
                            <span class="author">{{
                                    item.author ? item.author : 'A2B9S'
                            }}</span>
                            <h1 class="title">
                                <a href="#">{{
                                        item.title ? item.title : '新的日记'
                                }}</a>
                            </h1>
                            <p class="text">
                                {{ item.desc ? item.desc : '没有简介哦' }}
                            </p>
                            <a v-if="item.isRead === false && item.hasSercet === true" href="#"
                                @click="goUnLockDiary(item.Id)" class="button">留言来阅读</a>
                            <a v-else-if="item.hasSercet === false" href="#" @click="goReadDiaryNoSecret(item.Id)"
                                class="button">查看</a>
                            <a v-else href="#" @click="goReadDiary(item.Id)" class="button">查看</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="diaryData.length > 0" class="pagination">
            <el-pagination small :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next"
                :total="totalCount">
            </el-pagination>
        </div>
        <weacg-empty v-if="diaryData.length === 0"></weacg-empty>
    </div>
</template>

<script>
export default {
    name: 'weacgAnimButton',
    props: {
        diaryData: {
            type: Array,
            default: () => []
        },
        totalCount: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            currentPage: 1,
            seed: this.guid(),
            baseUrl: this.$weacg.BaseUrl,
        }
    },
    filters: {
        timeParse: function (value, type) {
            var timestamp = new Date(value)
            if (type == 'day') {
                var day = timestamp.getDate()
                if (day < 10) {
                    return `0${day}`
                } else {
                    return day
                }
            }
            if (type == 'month') {
                var month = timestamp.getMonth()
                switch (month + 1) {
                    case 1:
                        return 'Jan'
                    case 2:
                        return 'Feb'
                    case 3:
                        return 'Mar'
                    case 4:
                        return 'Apr'
                    case 5:
                        return 'May'
                    case 6:
                        return 'Jun'
                    case 7:
                        return 'Jul'
                    case 8:
                        return 'Aug'
                    case 9:
                        return 'Sep'
                    case 10:
                        return 'Oct'
                    case 11:
                        return 'Nov'
                    case 12:
                        return 'Dec'
                    default:
                        return 'Azm'
                }
            }
            if (type == 'year') {
                return timestamp.getFullYear()
            }
            return ''
        }
    },
    watch: {
        currentPage: {
            handler() {
                this.$emit('diaryPageChange')
            }
        }
    },
    methods: {
        getUserHeadImg(item) {
            if (item.hasImg) {
                return `${this.baseUrl}/CompressImage/${item.imgId}`
                // return `${this.baseUrl}/logo/loading1/gif`
            } else {
                return `https://picsum.photos/seed/${item.imgId}/400/400`
            }
        },
        S4() {
            return (((1 + Math.random()) * 0x10000) | 0)
                .toString(16)
                .substring(1)
        },
        guid() {
            return (
                this.S4() +
                this.S4() +
                '-' +
                this.S4() +
                '-' +
                this.S4() +
                '-' +
                this.S4() +
                '-' +
                this.S4() +
                this.S4() +
                this.S4()
            )
        },
        goReadDiaryNoSecret(id) {
            this.$emit('read-no-secret', id)
        },
        goUnLockDiary(id) {
            this.$emit('unlock', id)
        },
        goReadDiary(id) {
            this.$emit('read', id)
        }
    }
}
</script>

<style lang="scss" scoped>
// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #77d7b9;
$white: #fff;
$black: #000;
$open-sans: 'Open Sans', sans-serif;
// clear-fix mixin


.pagination {
    max-width: 90%;
    margin: auto;
    padding-bottom: 45px;
    margin-top: 20px;
}

/deep/.el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-prev {
        background: none !important;
    }

    .btn-next {
        background: none !important;
    }

}

/deep/.el-pager li {
    background: none !important;
}

@mixin mq-xs {
    @media (min-width: 320px) {
        @content;
    }
}

@mixin mq-sm {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin mq-md {
    @media (min-width: 720px) {
        @content;
    }
}

@mixin mq-lg {
    @media (min-width: 1000px) {
        @content;
    }
}

@mixin cf {

    &::before,
    &::after {
        content: '';
        display: table;
    }

    &::after {
        clear: both;
    }
}

a {
    text-decoration: none;
}

h1 {
    font-family: $open-sans;
    font-weight: 300;
}

.anim_btn {
    max-width: 80vw;
    height: 455px;
    padding-top: 5vh;
}

.btn_title {
    text-align: center;
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 20px;
    font-family: 'Indie Flower', cursive !important;
}

.row {
    max-width: 80vw;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    height: inherit;
    position: relative;
}

.empty {
    width: 100%;
    height: 100%;
    background: rgb(182, 182, 182);
}

// Base styles
.card {
    padding: 0 1rem 0 0;
    width: 55%;

    @include mq-lg {
        width: 30%;
    }

    display: inline-block;

    .menu-content {
        @include cf;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: inline-block;
        }

        a {
            color: $white;
        }

        span {
            position: absolute;
            left: 50%;
            top: 0;
            font-size: 10px;
            font-weight: 700;
            font-family: 'Open Sans';
            transform: translate(-50%, 0);
        }
    }

    .wrapper {
        background-color: $white;
        min-height: 30vh;
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        -webkit-box-shadow: 4px 3px 6px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 4px 3px 6px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 4px 3px 6px 0px rgba(50, 50, 50, 0.75);

        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        img[lazy='loaded'] {
            object-fit: cover;
            background-color: #c3c3c3;
        }

        img[lazy='loading'] {
            object-fit: none;
            background-color: #f1f1f1;
        }

        img[lazy='error'] {
            object-fit: none;
            background-color: #767676;
        }

        // box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.2);
        &:hover {
            .data {
                transform: translateY(0px);
            }
        }
    }

    .data {
        position: absolute;
        bottom: 0;
        width: 100%;
        transform: translateY(calc(70px + 1em));
        transition: transform 0.3s;

        .content {
            padding: 1em;
            position: relative;
            z-index: 1;
        }
    }

    .author {
        font-size: 12px;
    }

    .title {
        margin-top: 10px;
        font-size: 1rem;

        a {
            display: block;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .text {
        height: 70px;
        margin: 0;
        display: block;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $open-sans;
    }

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox']:checked+.menu-content {
        transform: translateY(-60px);
    }
}

// Second example styles
.example-2 {
    .wrapper {

        // background: url(https://tvseriescritic.files.wordpress.com/2016/10/stranger-things-bicycle-lights-children.jpg)
        //     center / cover no-repeat;
        &:hover {
            .menu-content {
                span {
                    transform: translate(-50%, -10px);
                    opacity: 1;
                }
            }
        }
    }

    .header {
        @include cf;
        color: $white;
        padding: 1em;
        position: absolute;
        right: 5%;

        .date {
            float: right;
            font-size: 12px;
        }
    }

    .menu-content {
        float: right;

        li {
            margin: 0 5px;
            position: relative;
        }

        span {
            transition: all 0.3s;
            opacity: 0;
        }
    }

    .data {
        color: $white;
        transform: translateY(calc(70px + 4em));
    }

    .title {
        a {
            color: $white;
        }
    }

    .button {
        display: block;
        width: 100px;
        margin: 2em auto 1em;
        text-align: center;
        font-size: 12px;
        color: $white;
        line-height: 1;
        position: relative;
        font-weight: 700;

        &::after {
            content: '\2192';
            opacity: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            transition: all 0.3s;
        }

        &:hover {
            &::after {
                transform: translate(5px, -50%);
                opacity: 1;
            }
        }
    }
}
</style>
