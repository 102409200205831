<template>
    <div id="loading-btn">
        <button @click="openDialog">
            <span>{{ title }}</span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'weacgTextBtn',
    props: {
        title: {
            type: String,
            default: 'Click Me'
        },
        type: {
            type: String,
            default: 'wish'
        }
    },
    data() {
        return {}
    },
    methods: {
        openDialog() {
            this.$emit('openDialog', this.type)
        }
    }
}
</script>

<style lang="scss" scoped>
@mixin mq-xs {
    @media (min-width: 320px) {
        @content;
    }
}

@mixin mq-sm {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin mq-md {
    @media (min-width: 720px) {
        @content;
    }
}

@mixin mq-lg {
    @media (min-width: 1000px) {
        @content;
    }
}

$body-width: 30vw;
$body-height: $body-width * 0.3;

#loading-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0 45px 0;
}

button {
    background: transparent;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: bold;
    color: #e98787;
    font-size: 20px;
    padding: 15px 50px;
    position: relative;
    transition: all 0.5s;
    border: 0.5px solid #e98787;
    outline: none;
}

// button:before {
//     transition: all 0.8s cubic-bezier(0.5, 0, 0, 1);
//     content: '';
//     line-height: 100%;
//     width: 1%;
//     height: 100%;
//     background: mistyrose;
//     position: absolute;
//     top: 0;
//     left: 0;
// }

// button span {
//     mix-blend-mode: darken;
// }

// button:hover:before {
//     background: mistyrose;
//     width: 100%;
// }

button:hover {
    color: #ffffff;
    transition: all 1s;
    background: #e98787;
}
</style>
