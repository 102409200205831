import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewRoutes from '../views'
import { isNotEmptyStr } from '../utils/index'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: ViewRoutes
})



router.beforeEach(async (to, from, next) => {
    let token = localStorage.getItem('Token')
    if (
        // 检查用户是否已登录
        token && isNotEmptyStr(token) && to.name === 'login'
    ) {
        // 将用户重定向到登录页面
        next({ name: 'birthday' })
    } else if (!isNotEmptyStr(token) && to.name !== 'login') {
        next({ name: 'login' })
    } else {
        next()
    }
})

export default router
