import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import components from './components'
import VueLazyLoad from 'vue-lazyload'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import './assets/fonts/fonts.css'
import './assets/svgs'
import global from './config/global.js'
import JsEncrypt from 'jsencrypt'
import vueg from 'vueg'
import MemoryStorage from './utils/nameStroge'
import { customAxios} from './api/api'
import moment from 'moment'





const options={  
    duration: '0.3',              // `animation-duration`  
    firstEntryDisable: false,     // When the value is true, in the process of the user into the app, app no transition effect,the default is false  
    firstEntryDuration: '.6',     //
    forwardAnim: 'fadeInRight',   // Forward transition effect,the default is fadeInRight  
    backAnim: 'fadeInLeft',       // Back transition effect,the default is fadeInLeft  
    sameDepthDisable: false,      // When the url depth is the same, the effect is disabled,the default is false   
    tabs: [{
            name:'home'
        },{
            name:'my'
        }],                       // the default is [],'name' is the name of the route,to achieve in the app click on the tab,the page horizontal transition effects,such as tabs[1] to tab [0],will use the backAnim effect,tabs[1] to tabs[2],will use the forwardAnim effect
    tabsDisable: false,           // when the value is true,disable the effect of the page in tabs,the default is false  
    disable: false,               // disable transition effect,the default is false,nested route default is true  
}  
Vue.use(vueg, router,options)

Vue.use(VueAwesomeSwiper)

Vue.use(VueLazyLoad, {
    preLoad: 1,
    error: require('./assets/imgs/loadfail.png'),
    loading: require('./assets/imgs/loading1.gif'),
    attempt: 2
})

Vue.config.productionTip = false
Vue.prototype.$weacg = global
Vue.prototype.$storage = new MemoryStorage()
Vue.prototype.$bus = new Vue()
Vue.prototype.$jsEncrypt = JsEncrypt
//注册到vue原型上
Vue.prototype.$moment = moment

moment.locale('zh-cn')

//axios.defaults.baseURL = 'http://1.116.117.225:8081/api/'

Vue.use(components)
Vue.use(ElementUI)
Vue.use(VueAxios, axios)

axios.defaults.baseURL = `${global.BaseUrl}/api/`

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
