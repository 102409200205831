<template>
    <div class="weacg-message">
        <h1 class="title">留言板</h1>
        <h5 class="tip">*你的留言会被永远记录，你可以选择留下名字和联系方式，也可以选择匿名留言</h5>
        <div class="swt">
            <el-switch v-model="isSercetMessage" active-text="匿名" active-color="#b7b7b7" inactive-color="#ff4949"
                inactive-text="实名">
            </el-switch>
        </div>
        <span>
            <input class="clean-slide" :class="`clean-slide-${isSercetMessage ? '3' : '0'}`" id="age" type="text"
                placeholder="感谢所有的祝愿!" v-model="form.context" /><label for="age">内容</label>
        </span>
        <span v-if="!isSercetMessage">
            <input class="clean-slide clean-slide-1" id="age" type="text" placeholder="留下名字!"
                v-model="form.name" /><label for="age">姓名</label>
        </span>
        <span v-if="!isSercetMessage">
            <input class="clean-slide clean-slide-2" id="age" type="text" placeholder="常联系!"
                v-model="form.phoneNumber" /><label for="age">手机号码</label>
        </span>
        <div class="btn">
            <weacg-send-button @btn-click="beforeSubmit" ref="subtn"></weacg-send-button>
        </div>


    </div>
</template>

<script>
import {
    addMessage
} from '@/api/api'
export default {
    name: 'weacgMessage',
    mounted() { },
    data() {
        return {
            isSercetMessage: true,
            form: {
                context: null,
                name: null,
                phoneNumber: null,
                token: null,
                cip: null,
                cname: null
            }
        }
    },
    methods: {
        beforeSubmit() {
            if (this.$refs.subtn.isAnim) {
                return
            }
            if (this.isEmptyStr(this.form.context)) {
                this.$notify({
                    title: '消息',
                    message: '留言不能为空',
                    position: 'top-right'
                })
                return
            }
            if (!this.isSercetMessage) {
                if (this.isEmptyStr(this.form.name) && this.isEmptyStr(this.form.phoneNumber)) {
                    this.$notify({
                        title: '消息',
                        message: '非匿名留言需要名字或者电话哦（至少一个）',
                        position: 'top-right'
                    })
                    return
                }
            }
            this.submit()
        },
        isEmptyStr(s) {
            if (typeof s === 'string' && s.length > 0) {
                return false
            }
            return true
        },
        reset() {
            this.form = {
                context: null,
                name: null,
                phoneNumber: null,
                token: null,
                cip: null,
                cname: null
            }
        },
        submit() {
            try {
                this.$bus.$emit('showLoading')
                this.$refs.subtn.isAnim = true
                this.$refs.subtn.checked = true
                this.form.token = this.$store.state.token
                this.form.cip = window.returnCitySN ? window.returnCitySN["cip"] : null
                this.form.cname =  window.returnCitySN ? window.returnCitySN["cname"] : null
                addMessage(this.form)
                    .then(res => {
                        if (res.data.success) {
                            this.reset()
                            this.$notify({
                                title: '消息',
                                message: res.data.msg,
                                position: 'top-right'
                            })
                        } else {
                            this.$notify({
                                title: '消息',
                                message: res.data.msg,
                                position: 'top-right'
                            })
                        }
                    })
            }
            catch {
                this.$notify({
                    title: '消息',
                    message: '哎呀，出错喽',
                    position: 'top-right'
                })
            }
            finally {
                this.$bus.$emit('closeLoading')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
input {
    -webkit-user-select: text !important;
}

/deep/.el-switch__label.is-active {
    color: #ed2553;
}

/deep/.el-switch__label {
    font-family: 'Indie Flower', cursive !important;
    font-weight: bold;
}

.weacg-message {
    font-family: 'Indie Flower', cursive !important;
    position: relative;
    background-color: #eee !important;
    border-radius: 4px;
    height: auto;
    padding: 5vh 0 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tip {
        text-align: center;
        width: 80vw;
        max-width: 800px;
        min-width: 215px;
        padding: 5px 0;
    }

    .swt {
        width: 50vw;
        max-width: 800px;
        min-width: 215px;
        padding: 10px 0;
    }

    .btn {
        position: relative;
        width: 50vw;
        max-width: 800px;
        min-width: 215px;
        padding: 10px 0;
    }

    span {
        position: relative;

        .clean-slide-0 {
            border-radius: 5px 5px 0px 0px;
        }

        .clean-slide-1 {
            border-radius: 0px;
        }

        .clean-slide-2 {
            border-radius: 0px 0px 5px 5px;
        }

        .clean-slide-3 {
            border-radius: 5px;
        }

        .clean-slide {
            position: relative;
            display: inline-block;
            width: 50vw;
            max-width: 800px;
            min-width: 215px;
            padding: 10px 0 10px 15px;
            font-family: "Open Sans", sans;
            font-weight: 400;
            color: #f78989;
            background: white;
            border: 0;

            outline: 0;
            text-indent: 60px; // Arbitrary.
            transition: all .3s ease-in-out;

            &::-webkit-input-placeholder {
                color: white;
                text-indent: 0;
                font-weight: 300;
            }

            +label {
                display: inline-block;
                position: absolute;
                transform: translateX(0);
                top: 0;
                left: 0;
                bottom: 0;
                padding: 13px 15px;
                font-size: 11px;
                line-height: 11px;
                font-weight: 700;
                text-transform: uppercase;
                color: #032429;
                text-align: left;
                text-shadow: 0 1px 0 rgba(255, 255, 255, .4);
                transition: all .3s ease-in-out, color .3s ease-out;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                overflow: hidden;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 100%;
                    bottom: 0;
                    width: 100%;
                    background: #f78989;
                    z-index: -1;
                    transform: translate(0);
                    transition: all .3s ease-in-out;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }
        }

        .clean-slide:focus,
        .clean-slide:active {
            color: #377D6A;
            text-indent: 0;
            background: #fff;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &::-webkit-input-placeholder {
                color: #aaa;
            }

            +label {
                color: #fff;
                text-shadow: 0 1px 0 rgba(19, 74, 70, .4);
                transform: translateX(-100%);

                &:after {
                    transform: translate(100%);
                }
            }
        }
    }

}
</style>
